/**
 *
 * TrabantDigitalHome
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link }  from 'react-router-dom';
// Utils
import request from '../../utils/request';
import { getServer } from '../../utils/utility';
import SidebarTD from "../SidebarTD";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {Chips} from "primereact/chips";
import { SelectButton } from 'primereact/selectbutton';

import 'primeicons/primeicons.css';
import {InputTextarea} from "primereact/inputtextarea";
import Uploader from "./uploader";

import './style.css';
import {Calendar} from "primereact/calendar";
import {Dialog} from "primereact/dialog";

class BildfrageEditor extends React.Component {
  state = { bildfrage: {}, faktenData: [{text: '', source: '', title: '', webFact: true}], totalSize: 0, isEdit: false };

  quartalItems = [
        {label: 'Q1', value: 1},
        {label: 'Q2', value: 2},
        {label: 'Q3', value: 3},
        {label: 'Q4', value: 4},
    ];

  constructor() {
      super();
      this.unpublish = this.unpublish.bind(this);
  }

  async componentDidMount() {
      let id = this.props.match.params.id;
      if (id != "neu"){
          const requestURL = `${getServer()}/bildfrage/${this.props.match.params.id}`;

          const bildfrage = await request(requestURL, { method: 'GET' });
          console.log(bildfrage)

          if (!bildfrage) {
              console.log('bildfrage nicht gefunden');
          }
          else {
              bildfrage.frage = bildfrage.question;
              if (bildfrage.answers)
                bildfrage.antworten = bildfrage.answers.split(';');

              let jahrDate = bildfrage.jahr;
              let jDate = new Date();
              jDate.setFullYear(jahrDate);
              bildfrage.jahr = jDate;
          }

          this.setState(
              {
                  bildfrage,
                  hasUpload: true,
                  isEdit: true,
                  fakten: bildfrage.facts.length,
                  faktenData: bildfrage.facts,
              }, () => {
              this.handleUploaded(bildfrage.image);
          });
      }
      else {
          this.setState({
              hasUpload: false,
              fakten: 1,
              faktenData: [{text: '', source: ''}],
              isEdit: false,
          });
      }
  }

  onClick = (id) => {
    this.props.history.push(`/bildfrage/${id}`);
  }

  setAnswer = (value) => {
    let antworten = this.state.bildfrage.antworten || [];
      this.setState({ bildfrage: { ...this.state.bildfrage, antworten: value } });
  }

  saveAndUpdateFacts = async (bildfrage) => {
      for (let i = -1; ++i < bildfrage.facts.length;) {
          // Neu angelegte Fakten erstellen
          if (!bildfrage.facts[i].id) {
              const createFactURL = `${getServer()}/facts`;
              let res = await request(createFactURL, {
                  method: 'POST',
                  body: { data: bildfrage.facts[i] },
              });

              bildfrage.facts[i].id = res.data.id;
          } else {
              // vorhandene updaten
              const createFactURL = `${getServer()}/facts/${bildfrage.facts[i].id}`;
              await request(createFactURL, {
                  method: 'PUT',
                  body: { data: bildfrage.facts[i] },
              });
          }
      }

      return bildfrage;
  }

  deleteFact = async (fact, i) => {
      console.log(fact);
      let faktenData = this.state.faktenData;
      let bildfrage = this.state.bildfrage;
      console.log(faktenData, bildfrage);

      // Fakt existiert schon remote
      if (fact.id) {
          const createFactURL = `${getServer()}/facts/${fact.id}`;
          await request(createFactURL, {
              method: 'DELETE',
          });

          // faktenData = faktenData.filter(f => f.id !== fact.id);
          // bildfrage.facts = bildfrage.facts.filter(f => f.id !== fact.id);
      }
      faktenData.splice(i, 1);
      if (bildfrage.facts) // nur bei edit vorhanden
        bildfrage.facts.splice(i, 1);

      this.setState({faktenData, bildfrage});
  }

  save = async () => {
      if (!this.state.fileId) {
          this.setState({ displayUploadError: true, uploadError: "Es wurde kein Bild hochgeladen. Bitte bestätigen Sie den Upload mit dem entsprechenden Button." });
          return;
      }
      if (!this.state.bildfrage.quartal || !this.state.bildfrage.jahr) {
          this.setState({ displayUploadError: true, uploadError: "Es muss ein Quartal und ein Jahr festgelegt werden." });
          return;
      }
      if (!this.state.bildfrage.frage || !this.state.bildfrage.antworten) {
          this.setState({ displayUploadError: true, uploadError: "Es muss eine Frage und min. eine Antwort angegeben werden." });
          return;
      }
      if (!this.state.bildfrage.artist) {
          this.setState({ displayUploadError: true, uploadError: "Es muss ein/e KünstelerIn festgelegt werden." });
          return;
      }

      if (this.state.isEdit) {
          let bildfrage = this.state.bildfrage;
          bildfrage.jahr = bildfrage.jahr.getFullYear();

          // Fakten müssen separat angelegt/verändert werden
          bildfrage = await this.saveAndUpdateFacts(bildfrage);

          const requestURL = `${getServer()}/bildfrage/${this.props.match.params.id}`;

          delete bildfrage.image;
          // delete bildfrage.facts;

          if (this.state.isEdit && !this.state.bildfrage.publishedAt) {
              bildfrage.answers =  bildfrage.antworten.join(';');
              bildfrage.question =  bildfrage.frage;
          }

          await request(requestURL, {
              method: 'PUT',
              body: { data: bildfrage },
          });
      }
      else {
          let bildfrage = this.state.bildfrage;
          bildfrage.jahr = bildfrage.jahr.getFullYear();

          const requestURL = `${getServer()}/bildfrage/`;
          await request(requestURL, {
              method: 'POST',
              body: {
                  bildfrage: bildfrage,
                  question: {
                      frage: this.state.bildfrage.frage,
                      antworten: this.state.bildfrage.antworten,
                  },
                  file: this.state.fileId,
                  fakten: this.state.faktenData,
              }
          });
      }

      window.location.href = '/bildfrage';
  }

  unpublish = async () => {
      const requestURL = `${getServer()}/bildfrage/unpublish/${this.props.match.params.id}`;
      await request(requestURL, {
          method: 'POST',
      });
      window.location.href = '/bildfrage';
  }

  delete = async () => {
      if (this.state.isEdit) {
          const requestURL = `${getServer()}/bildfrage/${this.props.match.params.id}`;

          await request(requestURL, {
              method: 'DELETE',
          });
      }

      window.location.href = '/bildfrage';
  }

  itemTemplate = (data, layout) => {
        if (layout === 'list') {
            return this.renderListItem(data);
        }

        if (layout === 'grid') {
            return (
                <></>
            );
        }
    }

    setFaktenTitle = (e, i) => {
        let faktenData = this.state.faktenData;
        faktenData[i].title = e.target.value;

        this.setState({faktenData});
    }

    setFaktenText = (e, i) => {
        let faktenData = this.state.faktenData;
        faktenData[i].text = e.target.value;

        if (faktenData[i].webFact) {
            faktenData[i].text = faktenData[i].text.substring(0,80);
        }

        this.setState({faktenData});
    }

    setFaktenQuelle = (e, i) => {
        let faktenData = this.state.faktenData;
        faktenData[i].source = e.target.value;

        this.setState({faktenData});
    }

    setFaktenWeb = (i) => {
        let faktenData = this.state.faktenData;

        // Alle anderen deaktivieren
        for (let i = -1; ++i < faktenData.length;) {
            faktenData[i].webFact = false;
        }

        faktenData[i].webFact = true;

        this.setState({faktenData});
    }

    handleUploaded = (bild) => {
      if (!bild || !bild.formats) {
          this.setState({
              fileId: undefined,
              // filePreview: `https://trabant-api.denkundmach.de${imageUrl}`,
              // filePreview: `http://localhost:1337${imageUrl}`,
              hasUpload: false,
          })
      }
      else {
          let image = bild.formats;
          let imageUrl;

          // suche das größte abgespeicherte Format
          if (image['xlarge']) {
              imageUrl = image['xlarge'].url;
          }
          else if (image['large']) {
              imageUrl = image['large'].url;
          }
          else if (image['medium']) {
              imageUrl = image['medium'].url;
          }
          else if (image['small']) {
              imageUrl = image['small'].url;
          }
          else if (image['thumbnail']) {
              imageUrl = image['thumbnail'].url;
          }

          this.setState({
              fileId: bild.id,
              filePreview: `https://trabant-api.denkundmach.de${imageUrl}`,
              // filePreview: `http://localhost:1337${imageUrl}`,
              hasUpload: true,
          });
      }
    }

  render() {
      const fileUpload = (
          <Uploader handler={this.handleUploaded}/>
      );
      const filePreview = (
          <div style={{ minHeight: '480px', maxHeight: '480px', height: '480px' }}>
              <img style={{ height: 'auto',  width: 'auto', maxWidth: "100%", maxHeight:"460px", objectFit: 'cover'}}
                   src={this.state.filePreview} />
          </div>
      )

      const fakten = [];

      let faktenData = this.state.faktenData;

      // Falls noch kein Fakt existiert
      if (faktenData.length === 0) {
          faktenData.push([{title: '', text: '', source: '', webFact: true}])
          this.setState({ faktenData: [{title: '', text: '', source: '', webFact: true}] })
      }

      for (let i = -1; ++i < faktenData.length;) {
          let isLast = ((i + 1) === faktenData.length)

          fakten.push((
              <>
                  <h5>Titel (optional)</h5>
                  <InputText
                      value={faktenData[i].title}
                      onChange={(e) => {
                          this.setFaktenTitle(e,i);
                      }}
                      className="input-full-width"
                      placeholder="z.&thinsp;B. Albert Ebert hatte einen Zwillingsbruder und eine Katze namens Muz ..."
                  />
                  <h5>Fakt</h5>
                  <InputTextarea
                      rows={5}
                      cols={50}
                      value={faktenData[i].text}

                      className="input-full-width"
                      onChange={(e) => {
                          this.setFaktenText(e,i);
                      }}
                      placeholder="z.&thinsp;B. Albert Ebert hatte einen Zwillingsbruder und eine Katze namens Muz ..."
                  />
                  {
                      faktenData[i].webFact === true ?
                          <div>{faktenData[i].text.length} von 80 Zeichen (Web-Fakt Begrenzung)</div> :
                          <></>
                  }
                  <h5>Quelle</h5>
                  <InputText value={faktenData[i].source} onChange={
                      (e) => {
                          this.setFaktenQuelle(e, i);
                      }
                  } className="input-full-width"
                    placeholder="..."/>

                  <Button className="p-button-danger"
                          style={{ marginRight: "1em", marginTop: "1em" }}
                          onClick={() => {
                              this.deleteFact(faktenData[i], i);
                          }}>Fakt entfernen</Button>
                  {
                      faktenData[i].webFact === true ?
                          <Button disabled>
                              Ist Webfakt
                          </Button> :
                          <Button className="main-color"
                                  onClick={() => {
                                      this.setFaktenWeb(i);
                                  }}
                          >
                              Als Webfakt setzen
                          </Button>
                  }
                  { isLast && faktenData.length < 6 ?
                      <>
                          <br/>
                          <Button className="main-color"
                                  style={{ marginRight: "1em", marginTop: "1em" }}
                                  onClick={() => {
                                      let faktenDatas = faktenData;
                                      faktenDatas.push({title: '', text: '', source: '', webFact: false});
                                      this.setState({faktenDatas});
                                  }}>Neuen Fakt hinzufügen</Button>
                      </> :
                      <></>

                  }
              </>
          ));
      }

      const uploadFehlerFooter = (
          <div>
              <Button label="Ok" icon="pi pi-times" onClick={() => {
                  this.setState({displayUploadError: false});
              }} />
          </div>
      );
      let uploadFehler = (
          <Dialog header="Störung"
                  visible={this.state.displayUploadError}
                  style={{ width: '50vw' }}
                  footer={uploadFehlerFooter}
                  onHide={() => this.setState({displayUploadError: false})}
          >
              <p>{this.state.uploadError}</p>
          </Dialog>
      )

    return (
      <div className="p-grid content-grid" style={{height: "100%"}}>

          <div className="p-col-2 sidebarTD" style={{
              justifyContent: "right",
              textAlign: "right",
              maxWidth: "275px"
          }}>
              <SidebarTD/>
          </div>
          <div className="p-col content-col">
              <div style={{ paddingLeft: "4em" }} className="btn-fn">
                  <Link to="/bildfrage" className="btn-edit-bild">
                      <Button
                          style={{ boxShadow: "0 0 6px gray" }}
                          className="p-m-3 p-button-raised p-button-plain-white">
                          <span>zurück zur Übersicht</span>
                      </Button>
                  </Link>
                  {
                      (this.state.bildfrage.publishedAt || this.state.bildfrage.publishedAt != null)
                      && this.state.isEdit
                      && !this.state.bildfrage.active?
                      <Button
                          style={{ boxShadow: "0 0 6px gray" }}
                          className="p-m-3 p-button-raised p-button-plain-white"
                          onClick={() => {
                          this.unpublish();
                      }}>Auf Entwurf setzen</Button>
                      :
                      <></>
                  }
                  <Button className="main-color p-m-3" onClick={this.save}>Entwurf speichern</Button>
                  <Button className="p-m-3 p-button-danger" disabled={this.state.bildfrage.active === true} onClick={this.delete}>Entwurf entfernen</Button>
              </div>

              <div className="p-col-12 p-grid form-grid">
                  <div className="p-col-5" style={{ paddingLeft: '5em', paddingRight: '3em'}}>
                      <div className="p-col-12 form-headline">Generelle Bildinformationen</div>

                      <h5>Künstler*in</h5>
                      <InputText value={this.state.bildfrage.artist}
                                 onChange={
                                    (e) => this.setState(
                                { bildfrage: { ...this.state.bildfrage, artist: e.target.value} }
                                )}
                                 className="input-full-width"
                                 name="artist"
                                 placeholder="z.&thinsp;B. Albert Ebert ..." />

                      <h5>Titel</h5>
                      <InputText value={this.state.bildfrage.title}
                                 onChange={
                                  (e) => this.setState(
                                      { bildfrage: { ...this.state.bildfrage, title: e.target.value} }
                                  )}
                                 className="input-full-width"
                                 name="title"
                                 placeholder="z.&thinsp;B. Kinderfest im Kleingartenverein ..." />

                      <h5>Jahr</h5>
                      <InputText value={this.state.bildfrage.year} onChange={
                          (e) => this.setState(
                              { bildfrage: { ...this.state.bildfrage, year: e.target.value} }
                          )
                      } className="input-full-width"
                        placeholder="z.&thinsp;B. 1959 ..."/>

                      <h5>Technik</h5>
                      <InputText value={this.state.bildfrage.technique} onChange={
                          (e) => this.setState(
                              { bildfrage: { ...this.state.bildfrage, technique: e.target.value} }
                          )
                      } className="input-full-width"
                        placeholder="z.&thinsp;B. Öl auf Holz ..."/>

                      <h5>Maße</h5>
                      <InputText value={this.state.bildfrage.size} onChange={
                          (e) => this.setState(
                              { bildfrage: { ...this.state.bildfrage, size: e.target.value} }
                          )
                      } className="input-full-width"
                        placeholder="z.&thinsp;B. 36 x 61 cm ..."/>

                      <h5>Standort</h5>
                      <InputText value={this.state.bildfrage.location} onChange={
                          (e) => this.setState(
                              { bildfrage: { ...this.state.bildfrage, location: e.target.value} }
                          )
                      } className="input-full-width"
                        placeholder="z.&thinsp;B. Kulturstiftung Sachsen, Kunstmuseum Moritzburg Halle (Saale) ..."/>

                      <h5>Quelle</h5>
                      <InputText value={this.state.bildfrage.source} onChange={
                          (e) => this.setState(
                              { bildfrage: { ...this.state.bildfrage, source: e.target.value} }
                          )
                      } className="input-full-width"
                        placeholder="z.&thinsp;B. Foto: Punctum/Bertram Kober © VG Bild-Kunst, Bonn 2021 ..."/>

                      <div className="p-col-12 form-headline">Frage & Antworten</div>

                      <h5>Frage</h5>
                      {
                          this.state.isEdit && this.state.bildfrage.publishedAt ?
                              <>
                                  <InputText
                                      value={this.state.bildfrage.frage}
                                      disabled
                                      className="input-full-width"
                                      placeholder="z.&thinsp;B. Was hörst du ..."/>

                                  <h5>Antworten <i>(mit Enter bestätigen)</i></h5>
                                  <Chips value={this.state.bildfrage.antworten}
                                         className="input-full-width"
                                         placeholder="z.&thinsp;B. Kinderstimmen ..."
                                         disabled/>
                              </>
                              :
                              <>
                                  <InputText value={this.state.bildfrage.frage} onChange={
                                      (e) => {
                                          this.setState(
                                              { bildfrage: { ...this.state.bildfrage, frage: e.target.value} });
                                      }
                                  } className="input-full-width"
                                    placeholder="z.&thinsp;B. Was hörst du?"/>

                                  <h5>Antworten <i>(mit Enter bestätigen)</i></h5>
                                  <Chips value={this.state.bildfrage.antworten}
                                         className="input-full-width"
                                         placeholder="z.&thinsp;B. Kinderstimmen ..."
                                         onChange={(e) => this.setAnswer(e.value)}/>
                              </>
                      }

                      <h5>Webseite - Werkeinführungstext</h5>
                      <InputTextarea
                          rows={5}
                          cols={50}
                          value={this.state.bildfrage.webtext}
                          name="webtext"
                          className="input-full-width"
                          onChange={
                              (e) => this.setState(
                                  { bildfrage: { ...this.state.bildfrage, webtext: e.target.value} }
                              )}
                          placeholder="Textbeschreibung für die Anzeige auf der Homepage ..."
                      />
                  </div>
                  <div className="p-col-5" style={{ paddingLeft: '3em', paddingRight: '3em'}}>
                      <div className="p-col-12 form-headline">
                          Werk des Quartals
                      </div>
                      <h5>Nummerierung</h5>
                      <InputText value={this.state.bildfrage.number}
                                 placeholder="z.&thinsp;B. I '22 ..."
                                 style={{ display: 'none' }}
                                 onChange={
                          (e) => this.setState(
                              { bildfrage: { ...this.state.bildfrage, number: e.target.value} }
                          )
                      } />

                      <div style={{ display: 'flex', flex: 'auto', justifyContent: 'space-evenly' }}>
                          <div>
                              <label for="quartalOption" style={{display: 'inline-block'}}>Quartal: </label>
                              <SelectButton
                                  id="quartalOption"
                                  value={this.state.bildfrage.quartal}
                                  options={this.quartalItems}
                                  onChange={(e) => {
                                      this.setState({bildfrage: {...this.state.bildfrage, quartal: e.value}}, () => {
                                          console.log(this.state.bildfrage.quartal);
                                      });
                                  }}></SelectButton>
                          </div>
                          <div>
                              <label for="num-cal" style={{display: 'inline-block'}}>Jahr: </label><br/>
                              <Calendar id="num-cal" value={this.state.bildfrage.jahr}
                                        onChange={
                                            (e) => {
                                                try {
                                                    this.setState({bildfrage: {...this.state.bildfrage, jahr: e.value}}, () => {
                                                        console.log(this.state.bildfrage.jahr);
                                                    });
                                                }
                                                catch (ex) {
                                                    console.log("No valid date");
                                                }
                                            }
                                        }
                                        view="month" dateFormat="yy" yearNavigator yearRange="2010:2030"
                              />
                          </div>
                      </div>
                      <h5>Werk</h5>
                      { this.state.hasUpload ? filePreview : fileUpload }

                      <div className="p-col-12 form-headline" style={{ marginTop: '0.75em' }}>Unsichtbares</div>
                      {fakten}
                  </div>
                  <div className="p-col-2"></div>
              </div>
          </div>
          {uploadFehler}
      </div>
    );
  }
}

BildfrageEditor.defaultProps = {};

BildfrageEditor.propTypes = {
  history: PropTypes.object.isRequired,
};

export default BildfrageEditor;
