import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';

import { MatomoProvider } from '@datapunt/matomo-tracker-react'
import instance from './utils/stats';
instance.enableLinkTracking(); // https://github.com/Amsterdam/matomo-tracker/tree/main/packages/react#spa-link-tracking

ReactDOM.render(
  <React.StrictMode>
      <MatomoProvider value={instance}>
          <App />
      </MatomoProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
