/**
 *
 * AuthPage
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import instance from './../../utils/stats';

// Utils
import auth from '../../utils/auth';
import { getServer } from '../../utils/utility';
import request from '../../utils/request';

import './style.css';
import {InputText} from "primereact/inputtext";
import {Password} from "primereact/password";
import {Card} from "primereact/card";
import {Button} from "primereact/button";

import Grafik91 from './../../assets/Gruppe91.png';
import LogoKunststiftung from './../../assets/LogoKunststiftung.png';

class AuthPage extends React.Component {
  state = { form: {}, errors: [], didCheckErrors: false };

  constructor() {
    super();
    this.onKeyUp = this.onKeyUp.bind(this);
  }

  componentWillReceiveProps(nextProps) { }

  componentDidMount(){
    document.addEventListener("keydown", this.onKeyUp);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyUp);
  }

  onKeyUp(event) {
    if (event.code === 'Enter') {
      this.handleSubmit();
    }
  }

  getRequestURL = () => {
    let requestURL;

    switch (this.props.match.params.authType) {
      case 'login':
        requestURL = `${getServer()}/auth/local`;
        break;
      case 'register':
        requestURL = `${getServer()}/auth/local/register`;
        break;
      case 'reset-password':
        requestURL = `${getServer()}/auth/reset-password`;
        break;
      case 'forgot-password':
        requestURL = `${getServer()}/auth/forgot-password`;
        break;
      default:
    }

    return requestURL;
  };

  handleSubmit = e => {
    if(e)
      e.preventDefault();

    // Keine leeren Felder f. user/pass
    if (!this.state.user || this.state.user === "") {
      document.querySelector('#identifier').classList.add('p-invalid');
      return;
    }
    if (!this.state.pass || this.state.pass === "") {
      document.querySelector('#password').classList.add('p-invalid');
      return;
    }

    const body = {
      identifier: this.state.user,
      password: this.state.pass,
      rememberMe: true,
    };
    const requestURL = this.getRequestURL();

    // This line is required for the callback url to redirect your user to app
    if (this.props.match.params.authType === 'forgot-password') {
      body['url'] = `${getServer()}/auth/reset-password`
    }

    request(requestURL, { method: 'POST', body })
      .then(response => {
        auth.setToken(response.jwt, body.rememberMe);
        auth.setUserInfo(response.user, body.rememberMe);

        instance.trackEvent({
          category: "auth", action: "login", name: 'success', value: true,
        });

        this.redirectUser();
      })
      .catch(err => {
        let errors = [];

        instance.trackEvent({
          category: "auth", action: "login", name: 'fail', value: false,
        });

        if (err.response) {
          errors.push(
            { name: 'identifier', errors: [err.response.payload.message] },
          );
        }
        else {
          errors.push(
              { name: 'identifier', errors: ['Fehler bei der Abfrage'] },
          );
        }

        //errors = errors.toString();
        this.setState({ didCheckErrors: !this.state.didCheckErrors, errors });
        console.log(Object.entries(errors));
      });
  };

  handleReset = () => {
    const body = {
      email: this.state.user
    };
    const requestURL = this.getRequestURL();

    request(requestURL, { method: 'POST', body })
        .then(response => {
          this.props.history.push('/');
        })
        .catch(err => {
          let errors = [
            { name: 'identifier', errors: [err.response.payload.message] },
          ];
          //errors = errors.toString();
          this.setState({ didCheckErrors: !this.state.didCheckErrors, errors });
          console.log(Object.entries(errors));
        });
  }

  redirectUser = () => {
    this.props.history.push('/');
  };

  /**
   * Check the URL's params to render the appropriate links
   * @return {Element} Returns navigation links
   */
  renderLink = () => {
    if (this.props.match.params.authType === 'login') {
      return (
        <div className={'forgot-link'}>
          <Link to="/auth/forgot-password">Passwort vergessen</Link>
        </div>
      );
    }

    return (
      <div>
        <Link to="/auth/login">Zurück zum Login</Link>
      </div>
    );
  };

  render() {
    const passField = (
        <div className="p-field" style={{
          marginTop: "2em", marginBottom: "2em"
        }}>
          <span className="p-float-label">
            <Password
                id="password"
                feedback={false}
                onChange={
                  (e) => {
                    this.setState({
                      pass: e.target.value
                    });
                    document.querySelector('#password').classList.remove('p-invalid');
                  }
                }
            />
            <label htmlFor="password">Passwort</label>
          </span>
        </div>
    );

    return (
      <div className="authPage">
        <img
            src={Grafik91}
            style={{ position: "absolute", width: "15em" }} />
        <img
          src={LogoKunststiftung}
          style={{ position: "absolute", right: "5em", top: "5em", width: "11em" }}
        />
        <div className="p-d-flex p-jc-center" style={{ zIndex: 10 }}>
          <div className="p-fluid p-col-11 p-md-4 p-lg-3 p-xl-2">
            <Card title="Trabant.digital">
              <div className="p-field">
                <span className="p-float-label">
                  <InputText id="identifier" onChange={(e) => {
                    this.setState({
                      user: e.target.value
                    });
                    document.querySelector('#identifier').classList.remove('p-invalid');
                  }} />
                  <label htmlFor="identifier">Benutzername</label>
                </span>
              </div>

              {this.props.match.params.authType === "login" ?
                  passField : (<></>)
              }

              { this.props.match.params.authType === "login" ?
                  (
                      <Button
                          className="yellow-color login-btn"
                          label="Einloggen ➔"
                          onClick={this.handleSubmit}
                          onKeyPress={this.onKeyUp}/>
                  ) :
                  (
                      <Button
                          className="yellow-color login-btn"
                          label="Passwort zurücksetzen"
                          onClick={this.handleReset}/>
                  )
              }

              <div className="linkContainer">{this.renderLink()}</div>
              <p>
                { this.state.didCheckErrors ? (
                    <span style={{color: "red"}}>Nutzername oder Passwort falsch</span>) :
                    (<></>)
                }
              </p>
            </Card>
          </div>
        </div>

      </div>
    );
  }
}

AuthPage.defaultProps = {};
AuthPage.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default AuthPage;
