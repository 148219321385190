/**
 *
 * TrabantDigitalHome
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import {Link, NavLink, Redirect} from 'react-router-dom';
import {Image} from "primereact/image";
import {Button} from "primereact/button";

import './style.css';
import auth from "../../utils/auth";

import LogoEcke from "../../assets/Logo-Ecke.png";
import LogoKunststiftung from "../../assets/LogoKunststiftung.png";
import LogoKunstvermittlung from "../../assets/KMB-KV-Logo_gelb.png";
import Gruppe90 from "./../../assets/image1001.png";
import Differenzmenge from "./../../assets/Differenzmenge14.png";

class SidebarTD extends React.Component {
  state = { products: [] };

  async componentDidMount() {
  }

  logout() {
      auth.clearToken();
      auth.clearUserInfo();
      auth.clearAppStorage();
      window.location.href = '/';
  }

  render() {
    return (
        <div className="sidebar" style={{
            height: '100%',
            position: 'relative',
        }}>
            <img
                className="logo-links"
                src={LogoKunstvermittlung}
            />
            <img
                src={LogoKunststiftung}
                style={{
                    position: "fixed",
                    width: "11em",
                    right: "5em",
                    top: "3em",
                    padding: "1em",
                    boxShadow: "grey 0px 0px 8px",
                    zIndex: "100",
                }}
            />


            <div style={{
                    marginTop: "9em",
                    zIndex: "10",
                    height:"36em",
                    backgroundImage: `url(${Gruppe90})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat"
                }}
            >
                <NavLink to="/bildfrage" style={{ position: "relative", top: "11em" }} className={isActive =>
                    "nav-link" + (!isActive ? " unselected" : "")
                }>
                    <Button label="WERK DES QUARTALS" style={{ width: "13.5em" }}/>
                </NavLink>

                <NavLink to="/manager"  style={{ position: "relative", top: "11em" }} className={isActive =>
                    "nav-link" + (!isActive ? " unselected" : "")
                }>
                    <Button
                        label="KURATION: SESSIONS"
                        style={{ width: "13.5em" }}
                    />
                </NavLink>

                <NavLink to="/settings"  style={{ position: "relative", top: "11em" }} className={isActive =>
                    "nav-link" + (!isActive ? " unselected" : "")
                }>
                    <Button
                        label="EINSTELLUNGEN"
                        style={{ width: "13.5em" }}
                    />
                </NavLink>

                <img style={{ position: "absolute", left: "0", bottom: "10em", width: "100%", height: "auto" }}
                    src={Differenzmenge}/>
                <Button className="button-logout"
                        onClick={this.logout}
                ><span>AUSLOGGEN</span></Button>

            </div>
        </div>
    );
  }
}

SidebarTD.defaultProps = {};

SidebarTD.propTypes = {
};

export default SidebarTD;
