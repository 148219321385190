/**
 *
 * AuthPage
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Utils
import auth from '../../utils/auth';
import { getServer } from '../../utils/utility';
import request from '../../utils/request';

import './style.css';
import {InputText} from "primereact/inputtext";
import {Password} from "primereact/password";
import {Card} from "primereact/card";
import {Button} from "primereact/button";

import Grafik91 from './../../assets/Gruppe91.png';
import LogoKunststiftung from './../../assets/LogoKunststiftung.png';

class AuthPage extends React.Component {
  state = { form: {}, errors: [], didCheckErrors: false };

  constructor() {
    super();
    this.onKeyUp = this.onKeyUp.bind(this);

    let code = new URLSearchParams(document.location.search).get('code');
    this.state.code = code;
  }

  componentWillReceiveProps(nextProps) { }

  componentDidMount(){
    document.addEventListener("keydown", this.onKeyUp);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyUp);
  }

  onKeyUp(event) {
    if (event.code === 'Enter') {
      this.handleSubmit();
    }
  }

  getRequestURL = () => {
    return `${getServer()}/auth/reset-password`;
  };

  handleSubmit = e => {
    if(e)
      e.preventDefault();

    // Keine leers Felde f. pass
    if (!this.state.pass || this.state.pass === "") {
      document.querySelector('#password').classList.add('p-invalid');
      return;
    }

    const body = {
      code: this.state.code,
      password: this.state.pass,
      passwordConfirmation: this.state.pass
    };
    const requestURL = this.getRequestURL();

    // This line is required for the callback url to redirect your user to app
    if (this.props.match.params.authType === 'forgot-password') {
      body['url'] = `${getServer()}/auth/reset-password`
    }

    request(requestURL, { method: 'POST', body })
      .then(response => {
        this.redirectUser();
      })
      .catch(err => {
        let errors = [
          { name: 'identifier', errors: [err.response.payload.message] },
        ];
        //errors = errors.toString();
        this.setState({ didCheckErrors: !this.state.didCheckErrors, errors });
        console.log(Object.entries(errors));
      });
  };

  redirectUser = () => {
    this.props.history.push('/');
  };

  /**
   * Check the URL's params to render the appropriate links
   * @return {Element} Returns navigation links
   */
  renderLink = () => {
      return (
        <div>
          <Link to="/auth/forgot-password">Passwort setzen</Link>
        </div>
      );
  };

  render() {
    const passField = (
        <div className="p-field" style={{
          marginTop: "2em", marginBottom: "2em"
        }}>
          <span className="p-float-label">
            <Password
                id="password"
                feedback={false}
                onChange={
                  (e) => {
                    this.setState({
                      pass: e.target.value
                    });
                    document.querySelector('#password').classList.remove('p-invalid');
                  }
                }
            />
            <label htmlFor="password">Passwort</label>
          </span>
        </div>
    );

    return (
      <div className="authPage">
        <img
            src={Grafik91}
            style={{ position: "absolute", width: "15em" }} />
        <img
          src={LogoKunststiftung}
          style={{ position: "absolute", right: "5em", top: "5em", width: "11em" }}
        />
        <div className="p-d-flex p-jc-center" style={{ zIndex: 10 }}>
          <div className="p-fluid p-col-11 p-md-4 p-lg-3 p-xl-2">
            <Card title="Trabant.digital">
              {passField}

              <Button
                  className="main-color login-btn"
                  label="Passwort setzen ➔"
                  onClick={this.handleSubmit}
                  onKeyPress={this.onKeyUp}/>
            </Card>
          </div>
        </div>

      </div>
    );
  }
}

AuthPage.defaultProps = {};
AuthPage.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default AuthPage;
