import { createInstance } from '@datapunt/matomo-tracker-react'
const instance = createInstance({
    urlBase: 'https://trabant.denkundmach.de',
    siteId: 2,
    // userId: undefined, // optional, default value: `undefined`.
    trackerUrl: 'https://stats.denkundmach.com/matomo.php', // optional, default value: `${urlBase}matomo.php`
    srcUrl: 'https://stats.denkundmach.com/matomo.js', // optional, default value: `${urlBase}matomo.js`
    disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
    heartBeat: { // optional, enabled by default
        active: true, // optional, default value: true
        seconds: 10 // optional, default value: `15
    },
    linkTracking: false, // optional, default value: true
    configurations: { // optional, default value: {}
        // any valid matomo configuration, all below are optional
        disableCookies: true,
        setSecureCookie: false,
        setRequestMethod: 'POST'
    }
})

export default instance;