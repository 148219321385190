/**
 *
 * Home
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class HomePage extends React.Component {
  render() {
    return (
      <div style={{ marginTop: '15%' }}>
        <h1>Login erfolgreich. Sie werden weitergeleitet.</h1>
            <script>
                window.setTimeout(function(){
                    window.location.href = "/manager"
                }, 5000);
            </script>
        <div>
          <Link to="/manager">
            Zum Manager
          </Link>
        </div>
      </div>
    );
  }
}

HomePage.propTypes = {
  history: PropTypes.object.isRequired,
};

export default HomePage;
