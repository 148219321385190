import React from 'react';
import {FileUpload} from "primereact/fileupload";
import auth from "../../utils/auth";
import {ProgressBar} from "primereact/progressbar";
import {Tag} from "primereact/tag";
import {Button} from "primereact/button";
import {Toast} from "primereact/toast";

class Uploader extends React.Component {
    constructor(props) {
        super(props);

        this.state = { filePreview: '', totalSize: 0, hasUpload: false };

        this.onTemplateRemove = this.onTemplateRemove.bind(this);
    }

    onBeforeUpload = (e) => {
        console.log(e);
    }

    onBeforeSend = (e) => {
        const token = auth.getToken();

        if (token) {
            e.xhr.setRequestHeader('Authorization', `Bearer ${token}`)
        }
    }

    strapiUpload(event) {
        console.log(event.files);
    }

    onUpload = (e) => {
        this.toast.show({severity: 'info', summary: 'Success', detail: 'File Uploaded'});
        // console.log(e, e.xhr && e.xhr.responseText);
        // if (e.xhr && e.xhr.responseText) {
        //     let bild = JSON.parse(e.xhr.responseText);
        //     console.log(bild);
        //     if (bild[0] && bild[0].formats) {
        //         let thumb = bild[0].formats.thumbnail;
        //         this.setState({filePreview: thumb.url, hasUpload: true})
        //     }
        // }
    }

    fileSize = "";
    onTemplateSelect = async (e) => {
        let totalSize = this.state.totalSize;
        console.log(e.files)

        for (let i = -1; ++i < e.files.length;) {
            totalSize += e.files[i].size;
            console.log(e.files[i])
            var img = document.createElement('img');
            img.src = e.files[i].objectURL;

            let onload = new Promise((res) => {
                img.onload = function() {
                    res(`${img.width} x ${img.height}`);
                }
            });

            this.fileSize = await onload;
        }

        this.setState({
            totalSize, fileSelected: true,
        });
    }

    onTemplateUpload = (e) => {
        let totalSize = 0;
        e.files.forEach(file => {
            totalSize += (file.size || 0);
        });

        this.setState({
            totalSize
        }, () => {
            this.toast.show({severity: 'info', summary: 'Success', detail: 'File Uploaded'});

            console.log(e, e.xhr && e.xhr.responseText);
            if (e.xhr && e.xhr.responseText) {
                let bild = JSON.parse(e.xhr.responseText);
                console.log(bild);
                if (bild[0] && bild[0].formats) {
                    let thumb = bild[0].formats.thumbnail;
                    this.props.handler(bild[0]);
                    // this.setState({filePreview: `https://trabant-api.denkundmach.de${thumb.url}`, hasUpload: true})
                }
            }
        });
    }

    onTemplateRemove = (file, callback) => {
        console.log(file, callback);
        this.setState((prevState) => ({
            totalSize: prevState.totalSize - file.size,
            fileSelected: false,
        }), callback);
    }

    onTemplateClear = () => {
        this.setState({ totalSize: 0 });
    }

    onBasicUpload = () => {
        this.toast.show({severity: 'info', summary: 'Success', detail: 'File Uploaded with Basic Mode'});
    }

    onBasicUploadAuto = () => {
        this.toast.show({severity: 'info', summary: 'Success', detail: 'File Uploaded with Auto Mode'});
    }

    /* Human readable filesize
    * https://stackoverflow.com/a/20732091
    */
    humanFileSize = (size) => {
        var i = Math.floor( Math.log(size) / Math.log(1024) );
        return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    };

    headerTemplate = (options) => {
        let { className, chooseButton, uploadButton, cancelButton } = options;
        const value = this.state.totalSize/30000000;
        // const formatedValue = this.fileUploadRef ? this.fileUploadRef.formatSize(this.state.totalSize) : '0 B';
        const formatedValue = this.state.totalSize !== 0 ? this.humanFileSize(this.state.totalSize) : '0';

        if (this.state.fileSelected) {
            chooseButton = (<></>);
        } else {
            uploadButton = (<></>);
        }

        return (
            <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
                {chooseButton}
                {uploadButton}
                <ProgressBar value={value} displayValueTemplate={() => `${formatedValue} / 30 MB`} style={{
                    width: '300px',
                    height: '20px',
                    marginLeft: 'auto'
                }}/>
            </div>
        );
    };

    itemTemplate = (file, props) => {
        console.log(props);
        return (
            <div className="p-d-flex p-ai-center p-flex-wrap">
                <div className="p-d-flex p-ai-center" style={{width: '60%'}}>
                    <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
                    <span className="p-d-flex p-dir-col p-text-left p-ml-3">
                        {file.name}
                        <small>{this.fileSize}</small>
                    </span>
                </div>
                <Tag value={this.humanFileSize(file.size)} severity="warning" className="p-px-3 p-py-2" />
                <Button
                    type="button"
                    icon="pi pi-times"
                    className="p-button-outlined p-button-rounded p-button-danger p-ml-auto"
                    onClick={() => {
                        this.onTemplateRemove(file, props.onRemove);
                    }} />
            </div>
        );
    }

    emptyTemplate() {
        return (
            <div className="p-d-flex p-ai-center p-dir-col">
                <i className="pi pi-image p-mt-3 p-p-5"
                   style={{'fontSize': '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)'}}></i>
                <span style={{'fontSize': '1.2em', color: 'var(--text-color-secondary)'}}
                      className="p-my-5">Bild hier ablegen oder Uploadbutton nutzen</span>
            </div>
        )
    }

    render() {
        const chooseOptions = {icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined'};
        const uploadOptions = {icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined'};
        const cancelOptions = {icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined'};

        return (
            <>
                <Toast ref={(el) => { this.toast = el; }}></Toast>
                <FileUpload ref={(el) => this.fileUploadRef = el}
                            name="files"
                            // disabled={this.state.fileSelected}
                            url="https://trabant-api.denkundmach.de/api/upload"
                            // url="http://localhost:1337/api/upload"
                            accept="image/*" maxFileSize={30000000}
                            onUpload={this.onTemplateUpload}
                            onBeforeUpload={this.onBeforeUpload}
                            onBeforeSend={this.onBeforeSend}
                    // auto
                            onSelect={this.onTemplateSelect}
                            onError={this.onTemplateClear}
                            onClear={this.onTemplateClear}
                            headerTemplate={this.headerTemplate}
                            itemTemplate={this.itemTemplate}
                            emptyTemplate={this.emptyTemplate}
                    // uploadHandler={this.strapiUpload}
                            withCredentials
                    // customUpload
                            chooseOptions={chooseOptions}
                            uploadOptions={uploadOptions}
                            cancelOptions={cancelOptions} />
            </>
        );
    }
}

Uploader.propTypes = {};

export default Uploader;