import {BrowserRouter, Link, Route, Switch} from "react-router-dom";
import NotFoundPage from "./container/NotFoundPage";
import PrivateRoute from "./container/PrivateRoute";
import AuthPage from "./container/AuthPage";
import AuthReset from "./container/AuthReset";
import Home from "./container/Home";
import {Component} from "react";
import Manager from "./container/Manager";
import auth from "./utils/auth";
import Bildfrage from "./container/Bildfrage";

import 'primeflex/primeflex.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import BildfrageEditor from "./container/BildfrageEditor";
import SessionPreview from "./container/SessionPreview";
import Webex from "./container/Webex";

class App extends Component {
  render() {
    let items = [
      {
        label: 'logout',
        onClick: () => {
          auth.clearAppStorage();
          window.location.href = '/auth/login';
        }
      },
    ];

    // let authedMenu = null;
    // if (auth.getUserInfo() != null) {
    //     authedMenu = (
    //         <Nav direction="row">
    //             <Anchor label="Sessions" href="/manager" />
    //             <Anchor label="Bilder & Fragen" href="/bildfrage" />
    //         </Nav>
    //     );
    // }

    return (
        <>
          <div style={{ width: "100%", height: "1em", backgroundColor: "black" }}></div>
          <BrowserRouter>
            <Switch>
              {/* A user can't go to the Home if is not authenticated */}
              <Route path="/auth/:authType/:id?" component={ AuthPage } />
              <Route path="/reset-password" component={ AuthReset } />
              <PrivateRoute path="/" component={ Home } exact />
              <PrivateRoute exact path="/manager" component={ Manager } />
              <PrivateRoute exact path="/bildfrage" component={ Bildfrage } />
              <PrivateRoute path="/bildfrage/:id" component={ BildfrageEditor } />
              <PrivateRoute path="/session/:id?" component={ SessionPreview } />
              <PrivateRoute path="/settings" component={ Webex } />
              <Route path="" component={NotFoundPage} />
            </Switch>
          </BrowserRouter>
        </>
    );
  }
}

export default App;
