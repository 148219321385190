const isEmpty = function (val) {
        if (val == null) return true;
        switch (typeof val) {
            case 'string':
                if (val === '') return true;
            case 'object':
                if (Object.keys(val).length === 0) return true;
        }

        return false;
    };

// const baseUrl = 'http://localhost:1337';
const baseUrl = 'https://trabant-api.denkundmach.de';
const getServer = function() {
    const basePath = '/api';

    return baseUrl + basePath;
}

const getHost = () => {
    return baseUrl;
}

export { isEmpty, getServer, getHost };