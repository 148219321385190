/**
 *
 * TrabantDigitalHome
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link }  from 'react-router-dom';
// Utils
import request from '../../utils/request';
import {getHost, getServer} from '../../utils/utility';
import SidebarTD from "../SidebarTD";
import {Button} from "primereact/button";
import {DataView} from "primereact/dataview";
import {Dialog} from "primereact/dialog";

import './style.css';
import {ScrollPanel} from "primereact/scrollpanel";
import { Scrollbars } from 'rc-scrollbars';

class Bildfrage extends React.Component {
  state = { showModal: false, };

  constructor() {
      super();
      this.publishImage = this.publishImage.bind(this);
  }

  async componentDidMount() {
    await this.updateDate();
  }

  updateDate = async () => {
        const requestURL = `${getServer()}/bildfrage`;

        let bildfragen = await request(requestURL, { method: 'GET' });

        if (!bildfragen || bildfragen.results.length === 0) {
            bildfragen.push(
                { price: 40, name: "masfafmosdop", description: "asfinsdiona sefioj s0d9 fsd " },
                { price: 40, name: "masfafmosdop", description: "asfinsdiona sefioj s0d9 fsd " },
                { price: 40, name: "masfafmosdop", description: "asfinsdiona sefioj s0d9 fsd " },
            );
        }

        for(let i = -1; ++i < bildfragen.results.length;) {
            let answers = bildfragen.results[i].answers;
            if (answers) {
                let answersArr = answers.split(';');
                bildfragen.results[i].answers = answersArr.join(', ');
            }
        }

        this.setState({ bildfragen: bildfragen.results });
    }

  onClick = (id) => {
    this.props.history.push(`/bildfrage/${id}`);
  }

    itemTemplate = (data, layout) => {
        if (layout === 'list') {
            return this.renderListItem(data);
        }

        if (layout === 'grid') {
            return (
                <></>
            );
        }
    }

    renderListItem(data) {
        let image, thumb, thumbPath;
        image = data.image;
        if (image && image.formats) {
            thumb = image.formats.thumbnail
            if (thumb) {
                thumbPath = thumb.url;
            }
        }

        let fakten = (<></>);
        if (data.facts) {
            fakten = [];
            for(let i = -1; ++i < data.facts.length;) {
                let fakt = (<li key={i}>
                    <span>{data.facts[i].text} </span><br></br>
                    <span style={{fontStyle: 'oblique'}}>{data.facts[i].source} </span>
                </li>);

                fakten.push(fakt);
            }
        }

        const facts = (
            <ul className="fact-list">{fakten}</ul>
        )

        return (
            <div className="p-col-5 p-grid bild-item">
                <span className="grid-item-number">{data.quartal} / {data.jahr}</span>
                <div className="p-col-12 p-grid grid-item" style={{
                    border: (data.active ? "4px solid #f99f2e" : "4px solid lightgray")
                }}>
                    <div className="p-grid bildinformationen-oben" style={{ width: "100%", margin: 0 }}>
                        <div style={{ width: "30%", height: "180px", padding: "0", textAlign: "center" }}>
                            <img style={{ maxHeight: "100%", maxWidth: "100%", height: "100%" }}
                                 src={`${getHost()}${thumbPath}`}
                                 onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}
                                 alt={data.name} />
                        </div>

                            <div style={{ width: "40%", padding: 0, height: "180px" }}>
                                <div className="trenner">Bildinformationen</div>
                                <Scrollbars className="p-col-12 bildinformationen" style={{ height: 145 }}>
                                <div className="bildinformationen">
                                    <div>{data.artist}</div>
                                    <div>{data.title}, {data.year}</div>
                                    <div>{data.technique}</div>
                                    <div>{data.size}</div>
                                    <br/>
                                    <div>{data.location}</div>
                                    <br/>
                                    <div>{data.source}</div>
                                </div>
                                </Scrollbars>

                            </div>

                        <div style={{ width: "30%", padding: 0, height: "180px" }}>
                            <div className="trenner">Datum</div>
                            <div className="bildinformationen">
                                Erstellt am: <br/>
                                {new Date(data.createdAt).toLocaleDateString()}
                            </div>
                            <div className="trenner">Status</div>
                            <div className="bildinformationen">{data.publishedAt ? (data.active ? "Aktives Werk" : "Inaktiv") : "Entwurf"}</div>
                            <div className="trenner">Kennzeichen</div>
                            <div className="bildinformationen">{data.number}</div>
                        </div>
                    </div>

                    <div className="p-col-12 trenner"><span>Frage</span></div>
                    <div className="p-col-12 frage">
                        {data.question}
                    </div>
                    <div className="p-col-12 trenner">
                        <span>Antworten</span>
                    </div>
                    <Scrollbars className="p-col-12 antwort" style={{ height: 60 }}>
                        <div className="p-col-12">
                            {data.answers}
                        </div>
                    </Scrollbars>

                    <div className="p-col-12 trenner">
                        Unsichtbares
                    </div>
                    {/*<div className="p-col-12 unsichtbares">*/}
                    {/*    {facts}*/}
                    {/*</div>*/}
                    <Scrollbars className="p-col-12 unsichtbares" style={{ height: 140 }}>
                        <div className="unsichtbares-content">
                            {facts}
                        </div>
                    </Scrollbars>
                </div>

                <div className="p-col-12" style={{ textAlign: "right", marginLeft: "2em" }}>
                    <Link to={"/bildfrage/" + data.id} className="btn-edit-bild">
                        <Button
                            className="main-color"
                            style={{ marginRight: "1em" }}>Bearbeiten</Button>
                    </Link>
                    {!data.publishedAt || data.publishedAt == null ?
                    <Button onClick={() => {
                        this.publishImage(data.id);
                    }}>Veröffentlichen</Button>
                    :
                        (data.active ?
                                <Button disabled className="main-color">Bereits aktiv</Button>
                                :
                                <Button
                                    className="p-button-secondary"
                                    onClick={() => this.setState({showModal: true, activeImageIntend: data.id})}
                                >Als aktives Bild markieren</Button>
                        )
                    }
                </div>

            </div>
        );
    }

    renderFooter(name) {
        return (
            <div>
                <Button label="Nein" icon="pi pi-times" onClick={() => this.setState({showModal: false})} className="p-button-text" />
                <Button
                    label="Ja"
                    icon="pi pi-check"
                    onClick={() =>
                    {
                        this.setState({showModal: false});
                        this.activateImage(this.state.activeImageIntend);
                    }} autoFocus />
            </div>
        );
    }

    activateImage = async (id) => {
        const requestURL = `${getServer()}/bildfrage/activate/${id}`;
        await request(requestURL, { method: 'POST' });
        await this.updateDate();
    }

    publishImage  = async (id) => {
        const requestURL = `${getServer()}/bildfrage/publish/${id}`;
        await request(requestURL, { method: 'POST' });
        await this.updateDate();
    }

  render() {
    return (
      <div className="p-grid content-grid" style={{height: "100%"}}>
          <div className="p-col-2 sidebarTD" style={{
              justifyContent: "right",
              textAlign: "right",
              maxWidth: "275px"
          }}>
              <SidebarTD/>
          </div>
          <div className="p-col content-col" style={{ 'overflow': 'hidden' }}>
              <Link to="/bildfrage/neu" style={{ paddingLeft: "0.9em", textDecoration: "none" }}>
                <Button
                    style={{ borderRadius: "5px" }}
                    className="main-color p-m-3 entwurf-btn">
                    <span>Entwurf anlegen</span>
                </Button>
              </Link>
              <div style={{ overflow: "auto", height: "85vh" }}>
                  <DataView value={this.state.bildfragen} layout={this.layout} itemTemplate={this.itemTemplate}/>
              </div>
          </div>
          <Dialog header="Bild des Quartals freischalten" visible={this.state.showModal} style={{ width: '50vw' }} footer={this.renderFooter()} onHide={() => this.setState({showModal: false})}>
              <p>Möchten Sie das Bild aktivieren?</p>
          </Dialog>
      </div>
    );
  }
}

Bildfrage.defaultProps = {};

Bildfrage.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Bildfrage;
