/**
 *
 * TrabantDigital Webex
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import {Link, NavLink, Redirect} from 'react-router-dom';
import {Button} from "primereact/button";

import './style.css';
import SidebarTD from "../SidebarTD";
import {TextInput} from "grommet";
import {InputNumber} from "primereact/inputnumber";
import auth from "../../utils/auth";
import {getServer} from "../../utils/utility";
import request from "../../utils/request";
import {Panel} from "primereact/panel";
import {Toast} from "primereact/toast";

class Webex extends React.Component {
    state = {
        optWebex: '', optQrHeight: '130',
    }

    constructor() {
        super();

        this.saveQrHeight = this.saveQrHeight.bind(this);
        this.updateData = this.updateData.bind(this);
    }

    async componentDidMount() {
        await this.loadData().catch(err => {
            auth.clearToken();
            auth.clearUserInfo();
            auth.clearAppStorage();
            window.location.href = '/';
        });
    }

    loadData = async () => {
        const requestURL = `${getServer()}/setting`;

        const settings = await request(requestURL, { method: 'GET' });
        let optWebex, optQrHeight;
        if (settings && settings.data) {
            if (settings.data.attributes) {
                optWebex = settings.data.attributes.webex;

                if (settings.data.attributes.app_settings) {
                    optQrHeight = settings.data.attributes.app_settings.qrHeight;
                }
            }
        }

        this.setState({
            optWebex, optQrHeight
        });
    }

    saveQrHeight = async (e) => {
        this.setState({optQrHeight: e.value});
    }

    updateData = async () => {
        let toast = this.toast;
        const requestURL = `${getServer()}/setting`;

        await request(requestURL, {
            method: 'PUT',
            body: {
                "data": {
                    "webex": this.state.optWebex,
                    "app_settings": {
                        "qrHeight": this.state.optQrHeight,
                    }
                }
            }
        }).then(() => {
            toast.show({severity: 'success', summary: 'Erfolgreich gespeichert'})
        }).catch((ex) => console.error(ex));
    }

    render() {
        return (
            <>
                <div className="p-grid content-grid" style={{height: "100%"}}>
                    <div className="p-col-2 sidebarTD" style={{
                        justifyContent: "right",
                        textAlign: "right",
                        maxWidth: "275px"
                    }}>
                        <SidebarTD/>
                    </div>
                    <div className="p-col content-col" style={{ marginLeft: "20px", marginTop: "8.5em" }}>
                        <Panel header="Einstellungen">
                            <div className="d-setting">
                                <label htmlFor="webex-link">Aktueller Link zur Webex Session: </label>
                                <TextInput id="webex-link"
                                           value={this.state.optWebex}
                                           onChange={
                                               (e) => this.setState(
                                                   { optWebex: e.target.value }
                                               )}></TextInput>
                            </div>
                            <div className="d-setting">
                                <label htmlFor="qrHeight">Höhe des QR-Codes (in cm): </label>
                                <br/>
                                <InputNumber id="qrHeight"
                                             value={this.state.optQrHeight}
                                             onValueChange={this.saveQrHeight}
                                             showButtons/>
                            </div>
                        </Panel>


                        <div className="d-setting">
                            <Button className="main-color p-m-3 save-settings-btn"
                                    onClick={this.updateData}>Einstellungen speichern</Button>
                        </div>
                    </div>
                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </>
        );
    }
}

Webex.defaultProps = {};

Webex.propTypes = {};

export default Webex;
