/**
 *
 * TrabantDigitalHome
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link }  from 'react-router-dom';
// Utils
import SidebarTD from "../SidebarTD";
import {Dialog} from "primereact/dialog";
import {getServer} from "../../utils/utility";
import request from "../../utils/request";
import {Button} from "primereact/button";

import "./style.css";

class SessionPreview extends React.Component {
    state = { displayPublish: false, displayDelete: false, displayError: false, noData: false, published: false };

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        let id = this.props.match.params.id;
        if (!id) {
          this.setState({ valid: false, id })
        }
        else {
          this.setState({ valid: true, id }, function () {
              this.checkForData();
          });
        }
    }

    checkForData = async () => {
        const requestURL = `${getServer()}/sessions/${this.state.id}`;
        await request(requestURL, {
            method: 'GET',
        }).then((res) => {
            console.log(res);
            if (!res.sessionUrl || res.sessionUrl === "") {
                this.setState({ noData: true })
            }
            if (res.publishedAt) {
                this.setState({ published: true });
            }
        }).catch((err) => {
            console.error(err);
            this.setState({ noData: true })
        });
    }

    publish = async () => {
        const requestURL = `${getServer()}/sessions/${this.state.id}`;
        await request(requestURL, {
            method: 'PUT',
            body: { data: { id: this.state.id, publishedAt: (new Date).toISOString() }},
        }).then(() => {
            window.location.href = "/manager";
        }).catch((err) => {
            console.error(err);
            this.setState({displayPublish: false, displayError: true});
        });
    }

    delete = async () => {
        const requestURL = `${getServer()}/sessions/${this.state.id}`;
        await request(requestURL, {
            method: 'DELETE',
        }).then(() => {
            window.location.href = "/manager";
        }).catch((err) => {
            console.error(err);
            this.setState({displayDelete: false, displayError: true});
        });
    }

  render() {
      const footerPublish = (
          <div>
              <Button label="Ja" icon="pi pi-check" onClick={this.publish} />
              <Button label="Nein" icon="pi pi-times" onClick={() => {
                  this.setState({displayPublish: false});
              }} />
          </div>
      );
      const footerDelete = (
          <div>
              <Button label="Ja" icon="pi pi-check" onClick={this.delete} />
              <Button label="Nein" icon="pi pi-times" onClick={() => {
                  this.setState({displayDelete: false});
              }} />
          </div>
      );
      const footerError = (
          <div>
              <Button label="Ok" icon="pi pi-times" onClick={() => {
                  this.setState({displayError: false});
              }} />
          </div>
      );

      const iframeSource = `https://assets.denkundmach.com/TD_Preview/?preview=${this.state.id}`;

    return (
        <>
            <div className="p-grid" style={{height: "100%"}}>

                <div className="p-col-2 sidebarTD" style={{
                    justifyContent: "right",
                    textAlign: "right",
                    maxWidth: "275px"
                }}>
                    <SidebarTD/>
                </div>
                <div className="p-col content-col">
                    <Link to={"/manager/"} style={{ textDecoration: 'none' }}>
                        <Button
                            className="p-m-3 p-button-raised p-button-plain-white btn-back"
                            style={{  }}>
                            Zurück zur Übersicht
                        </Button>
                    </Link>

                    <div className="p-col-12 p-grid form-grid">
                        <div className="p-col-8" style={{ paddingLeft: '41px', paddingRight: '1em'}}>
                            <p style={{
                                display: this.state.noData ? 'inline-block': 'none',
                                fontSize: '2em',
                                marginBottom: 0,
                                color: 'crimson',
                            }}
                            >Zu dieser Session sind keine Daten vorhanden.</p>

                            <div className="p-col-12 form-headline">Vorschau</div>
                            <iframe width="100%" height="650px" src={iframeSource} />
                        </div>
                        <div className="p-col-2" style={{paddingTop: '24px'}}>
                            { this.state.published ?
                                (<></>) :
                                <Button className="main-color p-m-3 btnPublish"
                                        onClick={() => {
                                            this.setState({displayPublish: true});
                                        }}
                                >Veröffentlichen</Button>
                            }
                            <Button className="p-button p-button-danger btnPublish"
                                    onClick={() => {
                                        this.setState({displayDelete: true});
                                    }}
                            >Löschen</Button>
                        </div>
                    </div>
                </div>
            </div>

            <Dialog header="Session veröffentlichen"
                    visible={this.state.displayPublish}
                    style={{ width: '50vw' }}
                    footer={ footerPublish }
                    onHide={() => this.setState({displayPublish: false})}
            >
                <p><span style={{fontWeight: 'bold'}}>Sind Sie sicher das Sie diese Session hochladen wollen?</span></p>
            </Dialog>
            <Dialog header="Session löschen"
                    visible={this.state.displayDelete}
                    style={{ width: '50vw' }}
                    footer={footerDelete}
                    onHide={() => this.setState({displayDelete: false})}
            >
                <p>
                    <span style={{fontWeight: 'bold'}}>Sind Sie sicher das Sie diese Session löschen wollen?</span>
                    Sie können diesen Vorgang nicht rückgängig machen.
                </p>
            </Dialog>
            <Dialog header="Störung"
                    visible={this.state.displayError}
                    style={{ width: '50vw' }}
                    footer={footerError}
                    onHide={() => this.setState({displayError: false})}
            >
                <p>Bei der Bearbeitung ist ein Fehler aufgetreten. [ERR21]</p>
            </Dialog>
        </>
    );
  }
}

SessionPreview.defaultProps = {};

SessionPreview.propTypes = {};

export default SessionPreview;
