/**
 *
 * TrabantDigitalHome
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import {Link, Route, Switch} from 'react-router-dom';

// import Table from '../../components/Table';
// Utils
import request from '../../utils/request';
import {getHost, getServer} from '../../utils/utility';
import AuthPage from "../AuthPage";
import PrivateRoute from "../PrivateRoute";
import Home from "../Home";
import Bildfrage from "../Bildfrage";
import NotFoundPage from "../NotFoundPage";
import SidebarTD from "../SidebarTD";
import {Button} from "primereact/button";
import {DataView} from "primereact/dataview";

import './style.css';
import {Dialog} from "primereact/dialog";
import {ProgressSpinner} from "primereact/progressspinner";
import auth from "../../utils/auth";
import {Paginator} from "primereact/paginator";

class Manager extends React.Component {
  state = { loading: true, displayError: false, };
  layout = "list";

  async componentDidMount() {
    await this.loadData().catch(err => {
        auth.clearToken();
        auth.clearUserInfo();
        auth.clearAppStorage();
        window.location.href = '/';
    });
  }

    loadData = async () => {
        const requestURL = `${getServer()}/sessions?_publicationState=preview`;

        const sessions = await request(requestURL, { method: 'GET' });
        if (!sessions || sessions.length === 0) {
            sessions.push(
                { price: 40, name: "masfafmosdop", description: "asfinsdiona sefioj s0d9 fsd " },
                { price: 40, name: "masfafmosdop", description: "asfinsdiona sefioj s0d9 fsd " },
                { price: 40, name: "masfafmosdop", description: "asfinsdiona sefioj s0d9 fsd " },
                { price: 40, name: "masfafmosdop", description: "asfinsdiona sefioj s0d9 fsd " },
                { price: 40, name: "masfafmosdop", description: "asfinsdiona sefioj s0d9 fsd " },
                { price: 40, name: "masfafmosdop", description: "asfinsdiona sefioj s0d9 fsd " },
                { price: 40, name: "masfafmosdop", description: "asfinsdiona sefioj s0d9 fsd " },
            );
        }

        this.setState({
            draftSessions: sessions.draftSessions,
            liveSessions: sessions.liveSessions,
            loading: false
        });
    }

    publishAll = async () => {
      this.setState({ loading: true, displayPublish: false }, async () => {
          for (let i = -1; ++i < this.state.draftSessions.length;) {
              let session = this.state.draftSessions[i];
              const requestURL = `${getServer()}/sessions/${session.id}`;
              await request(requestURL, {
                  method: 'PUT',
                  body: { id: session.id, publishedAt: (new Date).toISOString() },
              }).catch((err) => {
                  console.error(err);
                  this.setState({ displayError: true, loading: false });
              });
          }

          this.loadData();
      });
    }

  itemTemplate = (data, layout) => {
        if (layout === 'list') {
            return this.renderListItem(data);
        }

        if (layout === 'grid') {
            return (
                <></>
            );
        }
    }

    renderListItem(data) {
      let bildfrage = data.bildfrage;
      console.log(bildfrage);
      let image, thumb, thumbPath;
      if (bildfrage){
          image = bildfrage.image;
          if (image) {
              thumb = image.formats.thumbnail
              if (thumb) {
                  thumbPath = thumb.url;
              }
          }
      }
      else {
          data.bildfrage = {}; // dummy, falls nichts zugeordnet ist
      }

        return (
            <div className="p-col-9 p-grid session-item">
                <div className="p-col-3 session-item-image" style={{"padding": 0}}>
                    <img style={{maxWidth: "100%", height: "100%"}}
                        src={`${getHost()}${thumbPath}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={data.name} />
                </div>
                <div className="p-col-7 session-item-description" style={{padding: 0}}>
                    <table className="description-table" style={{ width: '100%', textAlign: 'right' }} border="0" cellspacing="0" cellpadding="1">
                        <thead>
                        <th style={{textAlign: "center"}}>ID</th>
                        <th style={{textAlign: "left", paddingLeft: "5em"}}>Bild des Quartals</th>
                        <th style={{textAlign: "left"}}>Wurde erstellt am</th>
                        <th style={{textAlign: "center"}}>Derzeitiger Status</th>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{textAlign: "center"}}>{data.id}</td>
                            <td style={{textAlign: "left", paddingLeft: "5em"}}>{data.bildfrage.artist}</td>
                            <td style={{textAlign: "left"}}>{new Date(data.createdAt).toLocaleDateString()}</td>
                            <td style={{textAlign: "center"}}>{data.publishedAt ? "Veröffentlicht" : "Nicht veröffentlicht"}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="p-col-2 session-item-fn">
                    <Link to={`/session/${data.id}`}>
                        <Button className="p-button-outlined p-button-secondary">Vorschau anzeigen</Button>
                    </Link>
                </div>
            </div>
        );
    }

    render() {
        const footerPublish = (
            <div>
                <Button label="Ja" icon="pi pi-check" onClick={this.publishAll} />
                <Button label="Nein" icon="pi pi-times" onClick={() => {
                    this.setState({displayPublish: false});
                }} />
            </div>
        );
        const footerError = (
            <div>
                <Button label="Ok" icon="pi pi-times" onClick={() => {
                    this.setState({ displayError: false });
                }} />
            </div>
        );

        return (
            <>
                <div className="p-grid content-grid" style={{height: "100%"}}>
                    <div className="p-col-2 sidebarTD" style={{
                        justifyContent: "right",
                        textAlign: "right",
                        maxWidth: "275px"
                    }}>
                        <SidebarTD/>
                    </div>

                    { /* Loading */ }
                    { this.state.loading ? (
                        <div
                            className="p-col"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <ProgressSpinner/>
                        </div>
                    ) : <></> }

                    { /* Content */ }
                    {
                        !this.state.loading ?
                            (
                                <div className="p-col content-col" style={{ marginLeft: "20px", marginTop: "0" }}>
                                    <Button
                                        className="main-color p-m-3"
                                        style={{ borderRadius: '4px' }}
                                        onClick={() => this.setState({displayPublish: true})}
                                    >
                                        Alle veröffentlichen
                                    </Button>
                                    <h2 style={{paddingLeft: "16px", marginTop: "2.5em"}}>Warten auf Freigabe</h2>
                                    <DataView value={this.state.draftSessions} layout={this.layout} itemTemplate={this.itemTemplate} paginator rows={4}/>
                                    <h2 style={{paddingLeft: "16px"}}>Veröffentlicht</h2>
                                    <DataView
                                        value={this.state.liveSessions}
                                        layout={this.layout}
                                        itemTemplate={this.itemTemplate}
                                        paginator
                                        rows={4}
                                    />
                                </div>
                            ) : <></>
                    }
                </div>

                <Dialog header="Alle veröffentlichen"
                        visible={ this.state.displayPublish }
                        style={{ width: '50vw' }}
                        footer={ footerPublish }
                        onHide={() => this.setState({ displayPublish: false })}
                >
                    <p>
                        <span style={{ fontWeight: 'bold' }}>Sind Sie sicher, das Sie alle ausstehenden Session hochladen wollen? </span>
                        Bevor Sie alle Interpretationen veröffentlichen, schauen Sie sich die Vorschau an.
                    </p>
                </Dialog>

                <Dialog header="Störung"
                        visible={ this.state.displayError }
                        style={{ width: '50vw' }}
                        footer={ footerError }
                        onHide={() => this.setState({ displayError: false })}
                >
                    <p>Bei der Bearbeitung ist ein Fehler aufgetreten. [ERR21]</p>
                </Dialog>
            </>
        );
  }
}

Manager.defaultProps = {};

Manager.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Manager;
